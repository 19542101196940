import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We regularly publish updates to documentation housed at `}<a parentName="p" {...{
        "href": "/"
      }}>{`docs.fastly.com`}</a>{`. Our documentation changelog lists all recent content organized by month and year. Recent items are shown below and older items are available separately in our `}<a parentName="p" {...{
        "href": "/changes/archive"
      }}>{`changelog archive`}</a>{`. Looking for a list of the most recent significant changes to documentation content? Check out the `}<a parentName="p" {...{
        "href": "/changes/significant/"
      }}>{`significant changelog`}</a>{`.`}</p>
    <p>{`You can subscribe to email updates of all documentation changes or just significant changes via the form on this page. We also make the documentation changes available via a `}<a parentName="p" {...{
        "href": "/changes/rss.xml"
      }}>{`machine-readable feed`}</a>{` for use with RSS-compatible software.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      